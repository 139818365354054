<template>
  <div class="flex flex-col">
    <div 
      v-if="$attrs.value && $attrs.value.length !== 0" 
      class="mb-3">
      <div
        v-for="(item, index) in $attrs.value"
        :key="`${index}`"
      >
        <GalleryItemForm
          class="w-5/12"
          v-model="$attrs.value[index]"
          :key-component="index"
          :col="col"
          :has-alt-text="hasAltText"
          :has-detail="hasDetail"
          :has-description="hasDescription"
          :has-ex-url="hasExUrl"
          :has-date="hasDate"
          :has-index="hasIndex"
          :showManageIcon="false"
        />
      </div>
    </div>
    <div>
      <MediaWidgetInit
        :folder="folder"
        :multiple="multiple"
        :selection-name="selectionName"
        :has-delete-button="hasDeleteButton && $attrs.value && $attrs.value.length > 0"
        resource-type="image"
        @selected-value="cldUpload.onSelectedValue"
        @render="cldUpload.onRender"
        @on-delete="onDelete"
      >
        <slot>Select File</slot>
      </MediaWidgetInit>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
import MediaWidgetInit from '@/components/MediaWidgetInit'
import useCldUpload from '@/use/useCldUpload'
import GalleryItemForm from '@/components/GalleryItemForm'
import env from '@/env'
import isEmpty from "lodash/isEmpty"

export default {
  name: 'ImageUpload',
  components: { MediaWidgetInit, GalleryItemForm },
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/images/tvc`,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selectionName: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: 240,
    },
    crop: {
      type: String,
      default: 'scale',
    },
    hasDeleteButton: {
      type: Boolean,
      default: false
    },
    hasAltText: {
      type: Boolean,
      default: false,
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
    hasDescription: {
      type: Boolean,
      default: false,
    },
    hasExUrl: {
      type: Boolean,
      default: false,
    },
    hasDate: {
      type: Boolean,
      default: false,
    },
    hasSelectable: {
      type: Boolean,
      default: false,
    },
    hasIndex: {
      type: Boolean,
      default: false,
    },
    col: {
      type: Number,
      default: 6,
    },
  },
  setup(props, ctx) {
    const cldUpload = useCldUpload(ctx)

    onMounted(() => {
      ctx.attrs.value = !isEmpty(ctx.attrs.value) ? ctx.attrs.value.map((value, index) => {
        return {
          ...value,
          image_index: index
        }
      }) : ctx.attrs.value
    })

    const imageFilter = computed(() => {
      return [
        'f_auto',
        props.width ? `w_${props.width}` : '',
        props.height ? `h_${props.height}` : '',
        props.crop ? `c_${props.crop}` : '',
      ]
        .filter((v) => v)
        .join(',')
    })

    const onDelete = () => {
      cldUpload.assets.value = []
      ctx.attrs.value = []
      ctx.emit('input', [])
    }

    return {
      cldUpload,
      imageFilter,
      onDelete,
    }
  },
}
</script>

<style scoped></style>
