<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>ชื่อ Project Review </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อ" 
                rules="required">
                <vs-input 
                  v-model="formData.title" 
                  class="w-full" 
                  name="title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>กำหนด URL พิเศษ (ห้ามใส่อักขระพิเศษ)</span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="URL พิเศษ" 
                rules="special_charecter|required">
                <vs-input 
                  v-model="formData.custom_slug" 
                  :disabled="currentLang != 'th'"
                  class="w-full" 
                  name="custom_slug" />
                 <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>ชื่อโครงการ </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อโครงการ" 
                rules="required">
                <div>
                  <v-select
                    class="w-6/12"
                    v-model="formData.project_id"
                    :options="projects.data"
                    label="title"
                    :reduce="project => project.id"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    placeholder="เลือกชื่อโครงการ"
                  />
                  <!-- <vs-select
                    v-model="formData.project_id"
                    name="ชื่อโครงการ"
                    class="selectExample"
                    icon="arrow_downward"
                    placeholder="เลือกชื่อโครงการ"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in projects.data"
                      :value="item.id"
                      :text="item.title"
                      :key="index"
                    />
                  </vs-select> -->
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียด</span>
            </FormLabel>
            <FormContent>
              <vs-textarea 
                v-model="formData.description" 
                rows="12"
                class="w-full" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แสดงที่หน้า Home</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.show_on_home" 
                name="show-on-home">Yes</vs-checkbox>
            </FormContent>
          </FormLayout>
          <FormLayout v-if="formData.show_on_home">
            <FormLabel>
              <span>เลือกลำดับ</span>
            </FormLabel>
            <FormContent class="flex flex-col">
              <div>
                <v-select
                  v-model="formData.position"
                  :options="positionList"
                  :reduce="position => position.value"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  placeholder="เลือก Position"
                  @input="confirmChangePosition"
                />
              </div>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รูปภาพ</span>
              <span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">นามสกุล .jpg / .png / .gif</span>
              <br >
              <span class="text-warning text-sm">ขนาด {{ validateThumbnailImageLabel }} px</span>
            </FormLabel>
            <FormContent class="flex flex-col md:flex-row md:w-9/12 md:flex-row-reverse">
              <div 
                v-if="formData.show_on_home" 
                class="w-full md:w-1/2">
                <img 
                  src="@/assets/images/project-revie-grid.jpg" 
                  alt="project-review-guideline"
                  class="w-full" >
              </div>
              <div 
                :class="{ 'md:w-full': !formData.show_on_home }" 
                class="w-full mt-3 md:w-1/2 md:mt-0">
                <ValidationProvider 
                  v-slot="{ errors }" 
                  :rules="validateThumbnailImage" 
                  name="รูปภาพ">
                  <ImageUpload
                    :folder="`${baseFolder}/project-review/${formData.folder_name}/Image`"
                    v-model="formData.thumbnail"
                    selection-name="thumbnail"
                    has-delete-button
                    crop="scale"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">
                    {{
                      errors[0]
                    }}
                  </span>
                </ValidationProvider>
              </div>
            </FormContent>
          </FormLayout>
          <FormLayout class="flex flex-col">
            <div class="vx-col w-full md:w-6/12 py-2">
              <span>Content Project Review</span>
              <p class="text-warning">**Support การ Upload Video ขนาดไม่เกิน 98MB</p>
            </div>
            <div class="vx-col w-full">
              <div class="content-editor">
                  <Wyswyg
                    :folder="`${baseFolder}/project-review/${formData.folder_name}/wyswyg`"
                    v-model="formData.content" />
              </div>
            </div>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>วันที่เผยแพร่ </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <div class="flex flex-col md:flex-row md:-mx-2">
                <div class="w-full mb-2 md:w-1/2 md:px-2">
                  <ValidationProvider 
                    v-slot="{ errors }" 
                    name="วันที่เผยแพร่" 
                    rules="required">
                    <datepicker
                      v-model="formData.published_date"
                      :highlighted="{
                        dates: [
                          // Highlight an array of dates
                          formData.published_date
                            ? new Date(formData.published_date)
                            : new Date(),
                        ],
                      }"
                      :disabled-dates="{
                        to: new Date(useDatepicker.moment().subtract(1, 'day'))
                      }"
                      :minimum-view="'day'"
                      :maximum-view="'year'"
                      format="dd MMMM yyyy"
                      placeholder="วันที่เผยแพร่"
                      required
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">
                      {{
                        errors[0]
                      }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card 
          class="mb-base" 
          title="SEO">
          <FormLayout>
            <FormLabel>
              <span>SEO Title </span>
              <span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 160 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO Title" 
                rules="required|max:160"
              >
                <vs-input 
                  v-model="formData.seo_title" 
                  class="w-full" 
                  :maxlength="160"
                  name="seo_title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO H1</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 255 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <vs-input 
                v-model="formData.seo_h1" 
                class="w-full" 
                name="seo_h1"
                :maxlength="255"
              />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Description </span>
              <span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 300 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO Description" 
                rules="required|max:300"
              >
                <vs-textarea
                  v-model="formData.seo_description"
                  class="w-full"
                  rows="7"
                  name="seo_description"
                  :maxlength="300"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Keywords </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO Keywords" 
                rules="required"
              >
                <vs-textarea 
                  v-model="formData.seo_keywords" 
                  class="w-full" 
                  rows="7"
                  name="seo_keywords" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Thumbnail </span>
              <br >
              <span class="text-warning text-sm">นามสกุล .jpg / .png / .gif</span>
              <br >
              <span class="text-warning text-sm">ขนาดรูปแนะนำ 600 x 338 px</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Thumbnail"
                rules="lh_mimes:jpg,png|lh_image_resolution:600,338"
              >
                <ImageUpload
                  :folder="`${baseFolder}/project-review/${formData.folder_name}/Seo Thumbnail`"
                  v-model="formData.seo_image"
                  selection-name="seo_image"
                  has-delete-button
                  crop="scale"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{
                    errors[0]
                  }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel />
            <FormContent class="flex space-x-3">
              <vs-button 
                v-if="formData.id !== null" 
                type="border" 
                @click="preview()">Preview</vs-button>
              <vs-button @click="submit(context)">{{ formData.id ? 'Update' : 'Submit' }}</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'
import VSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import env from '@/env'
import ImageUpload from '@/components/upload/ImageUpload'
import useDatepicker from '@/use/useDatepicker'
import useProjectReviewUtil from '@/use/useProjectReviewUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import omit from 'lodash/omit'
import find from 'lodash/find'
import Wyswyg from '@/components/Wyswyg'

export default {
  name: 'ProjectReviewForm',
  components: {
    ImageUpload,
    Datepicker,
    ValidationObserver,
    Wyswyg,
    VSelect
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError, ...notifyFn } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const {
      initialData,
      formData,
      projects,
      fetchByRouteId,
      fetchProjectList,
    } = useProjectReviewUtil(ctx)
    const crudForm = useCrud(ctx, 'projectReview', { fetchOnMount: false })
    crudForm.routePrefix.value = 'lh-content-project-review'

    const countProjectReviewByPosition = (position) => {
      return get($store.state.projectReview, 'items', []).filter(v => v.position === position).length
    }

    const positionList = computed(() => {
      return [
        {
          label: 'ไม่เลือก',
          value: null,
          validate: {},
          projectReviewCount: countProjectReviewByPosition(null) 
        },
        {
          label: countProjectReviewByPosition(1) > 0 ? '1 (ลำดับนี้ถูกใช้งานแล้ว)' : '1',
          value: 1,
          validate: {
            width: 1920,
            height: 1080
          },
          projectReviewCount: countProjectReviewByPosition(1) 
        },
        {
          label: countProjectReviewByPosition(2) > 0 ? '2 (ลำดับนี้ถูกใช้งานแล้ว)' : '2',
          value: 2,
          validate: {
            width: 720,
            height: 800 
          },
          projectReviewCount: countProjectReviewByPosition(2)
        },
        {
          label: countProjectReviewByPosition(3) > 0 ? '3 (ลำดับนี้ถูกใช้งานแล้ว)' : '3',
          value: 3,
          validate: {
            width: 1920,
            height: 1080
          },
          projectReviewCount: countProjectReviewByPosition(3)
        },
        {
          label: countProjectReviewByPosition(4) > 0 ? '4 (ลำดับนี้ถูกใช้งานแล้ว)' : '4',
          value: 4,
          validate: {
            width: 1920,
            height: 1080
          },
          projectReviewCount: countProjectReviewByPosition(4)
        },
        {
          label: countProjectReviewByPosition(5) > 0 ? '5 (ลำดับนี้ถูกใช้งานแล้ว)' : '5',
          value: 5,
          validate: {
            width: 1920,
            height: 1080
          },
          projectReviewCount: countProjectReviewByPosition(5)
        },
        {
          label: countProjectReviewByPosition(6) > 0 ? '6 (ลำดับนี้ถูกใช้งานแล้ว)' : '6',
          value: 6,
          validate: {
            width: 1920,
            height: 1080
          },
          projectReviewCount: countProjectReviewByPosition(6)
        }
      ]
    })

    const confirmChangePosition = (position) => {
      const positionSelected = find(positionList.value, (v) => v.value === position)
      if (position === initialData.position) {
        return
      }

      if (positionSelected && positionSelected.projectReviewCount > 0) {
        notifyFn.confirm({
          title: 'แจ้งเตือน',
          message: 'ลำดับนี้ถูกใช้งานแล้ว ต้องการดำเนินการต่อหรือไม่',
          callback: () => {
            // console.log({ result })
          },
          cancelCallback: () => {
            formData.position = initialData.position || null
          }
        }) 
      }
    }

    const validateThumbnailImage = computed(() => {
      const positionSelected = find(positionList.value, (v) => v.value === formData.position)
      // console.log({ positionSelected }, 'validate')
      if (positionSelected && positionSelected.validate.height && positionSelected.validate.width) {

        return `required|lh_mimes:jpg,png|lh_image_resolution:${positionSelected.validate.width},${positionSelected.validate.height}`
      }
      
      return 'required|lh_mimes:jpg,png|lh_image_resolution:1920,1080'
    })

    const validateThumbnailImageLabel = computed(() => {
      const positionSelected = find(positionList.value, (v) => v.value === formData.position)
      if (positionSelected && positionSelected.validate.height && positionSelected.validate.width) {

        return `${positionSelected.validate.width} x ${positionSelected.validate.height}`
      }
      

      return '1920 x 1080'
    })

    const initailPage = async () => {
      await $vs.loading()
      await fetchProjectList()
      await fetchByRouteId()
      await $vs.loading.close()
    }

    onMounted(async () => {
      await initailPage()
    })

    const updateItem = ({ validate }) => {
      validate().then((result) => {
        if (!result) {
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
          })
          return
        }

        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('projectReview/updateItem', { initialData,
            formData: {
              ...omit(formData, ['updated_at']),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'lh-content-project-review' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            if (
              error.message.split('unique constraint "content_slug_lang_key"').length > 1
            ) {
              notifyError({
                text:
                  'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากชื่อ หรือ URL พิเศษซ้ำกับข้อมูลที่มีอยู่ในระบบ',
              })
            } else {
              notifyError({ text: JSON.stringify(error) })
            }
          })
      })
    }

    const submit = (ctx) => {
      formData.title = formData.title.trim()
      formData.slug = isEmpty(formData.custom_slug)
        ? formData.title.replace(/[!@#$%^&*)(+=.?]/g, "").trim().split(' ').join('-')
        : formData.custom_slug.replace(/[!@#$%^&*)(+=.?]/g, "").trim().split(' ').join('-')

      formData.slug = formData.slug
      formData.custom_slug = formData.slug

      if (!formData.position) {
        formData.show_on_home = false
      }
      if (!formData.show_on_home) {
        formData.position = null
      }
      

      useDatepicker().moment.locale('th')
      formData.published_date = useDatepicker().moment(formData.published_date).format('YYYY-MM-DD')

      if (formData.id) {
        notifyFn.confirm({
          callback: () => {
            updateItem(ctx)
          }
        })
      } else {
        return crudForm.addItem(ctx, formData)
        .catch((error) => {
            $vs.loading.close()
            if (
              error.message.split('unique constraint "content_slug_lang_key"').length > 1
            ) {
              notifyError({
                text:
                  'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากชื่อ หรือ URL พิเศษซ้ำกับข้อมูลที่มีอยู่ในระบบ',
              })
            } else {
              notifyError({ text: JSON.stringify(error) })
            }
          })
      }
    }

    const preview = () => {
      const lang = $store.state.locale.currentLang
      const path = get(formData, 'slug')
      window.open(`${env.VUE_APP_WEBSITE_URL}/${lang}/review/${path}`)
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)
    const currentLang = computed(() => $store.state.locale.currentLang)

    return {
      ...crudForm,
      positionList,
      updateItem,
      initialData,
      formData,
      projects,
      submit,
      preview,
      confirmChangePosition,
      validateThumbnailImageLabel,
      validateThumbnailImage,
      useDatepicker: useDatepicker(),
      baseFolder,
      currentLang
    }
  },
}
</script>
