import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import env from '@/env'

export default ({ emit, props = { assets: [] } }) => {
  const cld = ref('')
  const assets = ref(props.assets || [])
  const cldName = ref(env.VUE_APP_CLOUDINARY_CLOUD_NAME)
  const imagePath = ref(env.VUE_APP_IMAGE_URL)

  const onSelectedValue = (value) => {
    assets.value = cloneDeep(value)
    emit('input', cloneDeep(value))
  }

  const onRender = (value) => {
    cld.value = value
  }

  const showFolder = (public_id) => {
    cld.value.show({
      asset: {
        resource_type: 'image',
        type: 'upload',
        public_id,
      },
    })
  }

  return {
    cld,
    assets,
    cldName,
    imagePath,
    onSelectedValue,
    onRender,
    showFolder,
  }
}
