export default ({ emit }) => {
  const editItem = () => {
    emit('edit-item')
  }

  const deleteItem = () => {
    emit('delete-item')
  }

  return {
    editItem,
    deleteItem,
  }
}
