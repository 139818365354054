import { ref, onMounted } from '@vue/composition-api'
import useCldUtil from '@/use/useCldUtil'

export default (ctx) => {
  const {
    emit,
    root: { $eventBus, $store },
  } = ctx
  const selectionName = ref('')
  const { setRequest } = useCldUtil(ctx)

  onMounted(() => {
    $eventBus.$on('cld-selected-value', () => {
      if ($store.state.cld.selectionName === selectionName.value) {
        emit('selected-value', $store.state.cld.assets)
      }
    })
  })

  const setSelectionName = (value) => {
    $store.dispatch('cld/setSelectionName', selectionName.value)
    selectionName.value = value
  }

  const openMediaWidget = () => {
    $store.dispatch('cld/setSelectionName', selectionName.value)
  }

  return {
    selectionName,
    setSelectionName,
    setRequest,
    openMediaWidget,
  }
}
