<template>
  <div>
    <button
      class="vs-component vs-button mr-3 mb-2 vs-button-primary vs-button-filled"
      type="button"
      @click="openWidget"
    >
      <slot>Select Image or Video</slot>
    </button>
    <button
      v-if="hasDeleteButton"
      class="vs-component vs-button mr-3 mb-2 vs-button-danger vs-button-filled"
      type="button"
      @click="onDelete"
    >
      DELETE
    </button>
  </div>
</template>

<script>
/* eslint-disable */
import useCld from '@/use/useCld'
import env from '@/env'

export default {
  name: 'MediaWidgetInit',
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/images`,
    },
    resourceType: {
      type: String,
      default: 'all',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selectionName: {
      type: String,
      required: true,
      // default: 'cld-selection-name-default'
    },
    hasDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { setSelectionName } = useCld(ctx)
    setSelectionName(props.selectionName)
    const openWidget = () => {
      setSelectionName(props.selectionName)
      ctx.emit('open-widget')
      window.ml.show({
        folder: {
          path: props.folder,
          resource_type: props.resourceType,
        },
        multiple: props.multiple,
        max_files: 50,
      })
    }

    const onDelete = () => {
      ctx.emit('on-delete')
    }

    return {
      openWidget,
      onDelete
    }
  },
}
</script>

<style>
.cloudinary-button {
}
</style>
