import get from 'lodash/get'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import { reactive } from '@vue/composition-api'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const {
    root: { $store },
  } = ctx

  const initialData = reactive({
    id: null,
    lang: null,
    title: '',
    project_id: null,
    slug: '',
    custom_slug: '',
    description: '',
    content: '',
    show_on_home: false,
    position: null,
    thumbnail: null,
    seo_title: null,
    seo_h1: null,
    seo_description: null,
    seo_keywords: null,
    seo_image: null,
    published_date: null,
    created_at: null,
    folder_name: null,
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  const formData = reactive({
    id: null,
    lang: null,
    title: '',
    project_id: null,
    slug: '',
    custom_slug: '',
    description: '',
    content: '',
    show_on_home: false,
    position: null,
    thumbnail: null,
    seo_title: null,
    seo_h1: null,
    seo_description: null,
    seo_keywords: null,
    seo_image: null,
    published_date: null,
    created_at: null,
    folder_name: null,
    updated_at: null,
    updated_by: null,
    created_by: null,
  })

  const projects = reactive({
    data: [],
  })

  const prepareFormData = (result) => {
    const data = omit(result, ['__typename'])
    forEach(formData, (value, key) => {
      formData[key] = data[key]
      initialData[key] = data[key]
    })

    if (!projects.data.find(v => v.id === formData.project_id)) formData.project_id = null
    formData.revision_id = data.revision_id
    formData.position = data.position || null
  }

  const fetchByRouteId = async () => {
    const id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Image', 'Seo Thumbnail']
    if (id) {
      formData.id = id
      // ctx.root.$vs.loading()
      $store
        .dispatch('projectReview/fetchOne', id)
        .then((result) => {
          prepareFormData(result)
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`project-review/${formData.folder_name}`, folders)
        })
        .then(() => ctx.root.$vs.loading.close())
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      // ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`project-review/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  const fetchProjectList = () => {
    return $store
      .dispatch('project/fetchDataListItems', {
        lang: $store.state.locale.currentLang,
      })
      .then((items) => {
        projects.data = items
        if (items.indexOf(formData.project_id) === -1) formData.project_id = null
      })
  }

  return {
    initialData,
    formData,
    projects,
    prepareFormData,
    fetchByRouteId,
    fetchProjectList,
  }
}
