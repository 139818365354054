var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-full gallery-form"},[_c('div',{staticClass:"flex-1"},[_c('cld-image',{attrs:{"public-id":_vm.$attrs.value.public_id,"cloud-name":_vm.env.VUE_APP_CLOUDINARY_CLOUD_NAME,"responsive":""}})],1),(_vm.hasAltText)?_c('div',{staticClass:"w-full mt-2"},[_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"Alt text"},model:{value:(_vm.$attrs.value.image_alt_text),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_alt_text", $$v)},expression:"$attrs.value.image_alt_text"}})],1):_vm._e(),(_vm.hasDetail)?_c('div',{staticClass:"w-full mt-2"},[_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"กรอกรายละเอียด"},model:{value:(_vm.$attrs.value.image_detail),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_detail", $$v)},expression:"$attrs.value.image_detail"}})],1):_vm._e(),(_vm.hasDescription)?_c('div',{staticClass:"w-full mt-2"},[_c('vs-textarea',{staticClass:"w-full",attrs:{"label":"กรอกคำอธิบาย"},model:{value:(_vm.$attrs.value.image_description),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_description", $$v)},expression:"$attrs.value.image_description"}})],1):_vm._e(),(_vm.hasExUrl)?_c('div',{staticClass:"w-full mt-2"},[_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"EX : http://lh.co.th or https://lh.co.th"},model:{value:(_vm.$attrs.value.image_ex_url),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_ex_url", $$v)},expression:"$attrs.value.image_ex_url"}})],1):_vm._e(),(_vm.hasDate)?_c('div',{staticClass:"w-full mt-2"},[_c('ValidationProvider',{attrs:{"vid":("gallery-item-start-date-" + _vm.keyComponent),"rules":'required',"name":"ช่วงเวลาเริ่มต้น"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Datepicker',{class:{'end-right': (_vm.itemNo + 1) % 4 === 0},attrs:{"highlighted":{
          dates: [
            // Highlight an array of dates
            _vm.$attrs.value.image_start_date
              ? new Date(_vm.$attrs.value.image_start_date)
              : new Date() ],
        },"disabled-dates":_vm.$attrs.value.image_end_date ? {
          from: new Date(_vm.$attrs.value.image_end_date)
        } : {},"format":"dd/MM/yyyy","placeholder":"ช่วงเวลาเริ่มต้น"},model:{value:(_vm.$attrs.value.image_start_date),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_start_date", $$v)},expression:"$attrs.value.image_start_date"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n        "+_vm._s(errors[0])+"\n      ")]):_vm._e()]}}],null,false,3973321255)})],1):_vm._e(),(_vm.hasDate)?_c('div',{staticClass:"w-full mt-2"},[_c('ValidationProvider',{attrs:{"vid":("gallery-item-start-end-" + _vm.keyComponent),"rules":'required',"name":"ช่วงเวลาสิ้นสุด"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('Datepicker',{class:{'end-right': (_vm.itemNo + 1) % 4 === 0},attrs:{"highlighted":{
          dates: [
            // Highlight an array of dates
            _vm.$attrs.value.image_end_date
              ? new Date(_vm.$attrs.value.image_end_date)
              : new Date() ],
        },"disabled-dates":_vm.$attrs.value.image_start_date ? {
          to: new Date(_vm.$attrs.value.image_start_date)
        } : {},"format":"dd/MM/yyyy","placeholder":"สิ้นสุด"},model:{value:(_vm.$attrs.value.image_end_date),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_end_date", $$v)},expression:"$attrs.value.image_end_date"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n        "+_vm._s(errors[0])+"\n      ")]):_vm._e()]}}],null,false,2936274212)})],1):_vm._e(),(_vm.hasSelectable)?_c('div',{staticClass:"w-full mt-2"},[_c('vs-radio',{attrs:{"vs-value":true},on:{"input":_vm.handleSelected},model:{value:(_vm.$attrs.value.image_selected),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "image_selected", $$v)},expression:"$attrs.value.image_selected"}},[_vm._v(_vm._s(_vm.selectRadioLabel)+"\n    ")])],1):_vm._e(),(_vm.hasIndex)?_c('div',{staticClass:"w-full mt-2 flex justify-center"},[_c('span',[_vm._v("ลำดับที่ "+_vm._s(_vm.keyComponent + 1))])]):_vm._e(),(_vm.showManageIcon)?_c('div',{staticClass:"flex mt-2 justify-center"},[_c('vs-icon',{staticClass:"cursor-pointer",attrs:{"icon":"edit","size":"small","round":""},on:{"click":_vm.editItem}}),_c('vs-icon',{staticClass:"cursor-pointer",attrs:{"icon":"delete","size":"small","round":""},on:{"click":_vm.deleteItem}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }