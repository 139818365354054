<template>
  <div class="flex flex-col h-full gallery-form">
    <div class="flex-1">
      <cld-image 
        :public-id="$attrs.value.public_id" 
        :cloud-name="env.VUE_APP_CLOUDINARY_CLOUD_NAME" 
        responsive/>
    </div>
    <div 
      v-if="hasAltText" 
      class="w-full mt-2">
      <vs-input
        v-model="$attrs.value.image_alt_text"
        placeholder="Alt text"
        class="w-full"
      />
    </div>
    <div 
      v-if="hasDetail" 
      class="w-full mt-2">
      <vs-input
        v-model="$attrs.value.image_detail"
        placeholder="กรอกรายละเอียด"
        class="w-full"
      />
    </div>
    <div 
      v-if="hasDescription" 
      class="w-full mt-2">
      <vs-textarea
        v-model="$attrs.value.image_description"
        label="กรอกคำอธิบาย"
        class="w-full"
      />
    </div>
    <div 
      v-if="hasExUrl" 
      class="w-full mt-2">
      <vs-input
        v-model="$attrs.value.image_ex_url"
        placeholder="EX : http://lh.co.th or https://lh.co.th"
        class="w-full"
      />
    </div>
    <div 
      v-if="hasDate" 
      class="w-full mt-2">
      <ValidationProvider
        v-slot="{ errors }"
        :vid="`gallery-item-start-date-${keyComponent}`"
        :rules="'required'"
        name="ช่วงเวลาเริ่มต้น"
      >
        <Datepicker
          v-model="$attrs.value.image_start_date"
          :highlighted="{
            dates: [
              // Highlight an array of dates
              $attrs.value.image_start_date
                ? new Date($attrs.value.image_start_date)
                : new Date(),
            ],
          }"
          :disabled-dates="$attrs.value.image_end_date ? {
            from: new Date($attrs.value.image_end_date)
          } : {}"
          format="dd/MM/yyyy"
          placeholder="ช่วงเวลาเริ่มต้น"
          :class="{'end-right': (itemNo + 1) % 4 === 0}"
        />
        <span 
          v-if="errors.length > 0" 
          class="text-danger text-sm">
          {{ errors[0] }}
        </span>
      </ValidationProvider>
    </div>
    <div 
      v-if="hasDate" 
      class="w-full mt-2">
      <ValidationProvider
        v-slot="{ errors }"
        :vid="`gallery-item-start-end-${keyComponent}`"
        :rules="'required'"
        name="ช่วงเวลาสิ้นสุด"
      >
        <Datepicker
          v-model="$attrs.value.image_end_date"
          :highlighted="{
            dates: [
              // Highlight an array of dates
              $attrs.value.image_end_date
                ? new Date($attrs.value.image_end_date)
                : new Date(),
            ],
          }"
          :disabled-dates="$attrs.value.image_start_date ? {
            to: new Date($attrs.value.image_start_date)
          } : {}"
          format="dd/MM/yyyy"
          placeholder="สิ้นสุด"
          :class="{'end-right': (itemNo + 1) % 4 === 0}"
        />
        <span 
          v-if="errors.length > 0" 
          class="text-danger text-sm">
          {{ errors[0] }}
        </span>
      </ValidationProvider>
    </div>
    <div 
      v-if="hasSelectable" 
      class="w-full mt-2">
      <vs-radio
        v-model="$attrs.value.image_selected"
        :vs-value="true"
        @input="handleSelected"
      >{{selectRadioLabel}}
      </vs-radio>
    </div>
    <div 
      v-if="hasIndex" 
      class="w-full mt-2 flex justify-center">
      <span>ลำดับที่ {{keyComponent + 1}}</span>
    </div>
    <div class="flex mt-2 justify-center" v-if="showManageIcon">
      <vs-icon
        icon="edit"
        size="small"
        round
        class="cursor-pointer"
        @click="editItem"
      />
      <vs-icon
        icon="delete"
        size="small"
        round
        class="cursor-pointer"
        @click="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch } from '@vue/composition-api'
import { CldImage } from 'cloudinary-vue'
import useGalleryItemForm from '@/use/useGalleryItemForm'
import useDatepicker from '@/use/useDatepicker'
import Datepicker from 'vuejs-datepicker'
import env from '@/env'

export default {
  name: 'GalleryItemForm',
  components: { CldImage, Datepicker },
  props: {
    keyComponent: {
      type: Number,
      default: 1,
    },
    hasAltText: {
      type: Boolean,
      default: false,
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
    hasDescription: {
      type: Boolean,
      default: false,
    },
    hasExUrl: {
      type: Boolean,
      default: false,
    },
    hasDate: {
      type: Boolean,
      default: false,
    },
    hasSelectable: {
      type: Boolean,
      default: false,
    },
    hasIndex: {
      type: Boolean,
      default: false,
    },
    selectRadioLabel: {
      type: String,
      default: 'เลือก'
    },
    showManageIcon: {
      type: Boolean,
      default: true,
    },
    itemNo: {
      type: Number
    }
  },
  setup(props, ctx) {
    const validateStartDate = computed(() => {
      if (props.hasDate && ctx.attrs.image_end_date) {
        return 'required'
      }

      return ''
    })

    const handleSelected = () => {
      ctx.emit('on-selected')
    }

    onMounted(() => {
      ctx.attrs.value.image_index = props.keyComponent
    })

    watch(
      () => ctx.attrs.value,
      () => {
        ctx.attrs.value.image_index = props.keyComponent
      },
      {
        deep: true,
      },
    )

    return {
      validateStartDate,
      useDatepicker: useDatepicker(ctx),
      ...useGalleryItemForm(ctx),
      handleSelected,
      env
    }
  },
}
</script>

<style >
  .gallery-form .rtl{
    direction: rtl;
  }

  .gallery-form .rtl div:first-child{
    direction: ltr ;
  }
  .end-right .vdp-datepicker__calendar {
      right: 0;
  }
</style>
