<template>
  <froala id="edit"
    :config="config" 
    v-model="$attrs.value"
    @input="$emit('input', $attrs.value)"
  >
  </froala>
</template>

<script>
/* eslint-disable */
import get from 'lodash/get'
import omit from 'lodash/omit'
import { ref, watch } from '@vue/composition-api'
import { propertiesCldForUpload } from '@/helper/damUtils'
import env from '@/env'

export default {
  name: 'Wyswyg',
  props: {
    folder: {
      type: String,
      default: 'wyswyg' 
    }
  },
	setup(props, ctx) {
    const content = ref('')
    const folder = ref('')
    const paramsUpload = propertiesCldForUpload(props.folder, 'video')
    const paramsUploadFile = propertiesCldForUpload(props.folder, 'raw')
    const config = ref({
      key: env.VUE_APP_FROALA_KEY,
      events: {
        'image.error': function (error, response) {
          console.log({ error, response })
        },
        'video.uploaded': function (res) {
          const data = JSON.parse(res)
          this.video.insert(
            `<video src="${data.secure_url}" style="width: 600px;" controls>Your browser does not support HTML5 video.</video>`,
          )
          return false
        },
        'file.uploaded': function (res) {
          const data = JSON.parse(res)
          const files = data.public_id.split('/')
          const fileSplit = get(files, files.length - 1, '').split('.')
          this.file.insert(data.secure_url, `${data.original_filename}.${get(fileSplit, fileSplit.length - 1)}`)
          return false
        },
      },
      imageUploadURL: env.VUE_APP_CLOUDINARY_API_UPLOAD,
      imageUploadParams: {
        folder: folder
      },
      videoUploadURL: paramsUpload.apiUrl,
      videoUploadParams: {
        folder: folder,
        ...omit(paramsUpload, ['apiUrl'])
      },
      fileUploadURL: paramsUploadFile.apiUrl,
      fileUploadParams: {
        folder: folder,
        ...omit(paramsUploadFile, ['apiUrl'])
      },
      videoInsertButtons: ['videoByURL', 'videoEmbed', 'videoUpload'],
      imageInsertButtons: ['imageUpload', 'imageByURL'],
      fontFamily: {
        "Kittithada,sans-serif, Roboto": "Kittithada",
        "KittithadaBold75,sans-serif": "KittithadaBold",
        "Kittithada,sans-serif": "LHfont",
        "Roboto,sans-serif": 'Roboto',
        "Oswald,sans-serif": 'Oswald',
        "Montserrat,sans-serif": 'Montserrat',
        "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
      },
      fontFamilySelection: true,
      fontSize: ['8', '9', '10', '11', '12', '13',
        '14', '15', '16', '17', '18', '20', '21',
        '22', '24', '26', '28', '30', '32', '34', '36', '48',
        '60', '72', '96'],
      fontSizeDefaultSelection: '20',
      fontSizeSelection: true,
      colorsText: ['#6f9755', '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC', '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000', '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF', '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'],
      colorsBackground: ['#6f9755', '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC', '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000', '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF', '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'],
      heightMin: 400,
      heightMax: 650,
      attribution: false,
      alwaysVisible: true,
      toolbarButtons:
        [['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough',
          'subscript', 'superscript', '|', 'fontFamily', 'fontSize',
          'textColor', 'backgroundColor', 'inlineStyle', 'paragraphStyle', '|',
          'paragraphFormat', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify',
          'formatOL', 'formatUL', 'lineHeight', 'outdent', 'indent', 'quote'], [
          'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|',
          'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', 'print',
          'spellChecker', 'help', 'html', '|', 'undo', 'redo']
        ]
      ,
      quickInsertEnabled: false
    })
    
    watch(
      () => props.folder, 
      (newValue) => {
        folder.value = newValue
      }
    )

		return {
      content,
			config
		}
  }
}
</script>

<style>
s {
  text-decoration: line-through;
}
</style>
