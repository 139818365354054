/* eslint-disable-next-line */
export default (ctx) => {
  const setRequest = (asset) => {
    return [
      {
        public_id: asset.file_name,
        format: asset.mime_type,
        url: asset.file_url,
        bytes: asset.file_size,
        height: asset.height,
        width: asset.width,
        resource_type: asset.resource_type,
      },
    ]
  }

  return {
    setRequest,
  }
}
